var unique = 'cloth_';
var set = (key,val)=>{
	localStorage.setItem(`${unique}${key}`,JSON.stringify(val));
}
var get = (key)=>{
	try{
		return JSON.parse(localStorage.getItem(`${unique}${key}`))
	}catch{
		return localStorage.getItem(`${unique}${key}`)
	}
}
var remove = (key)=>{
 	localStorage.removeItem(`${unique}${key}`)
}
var clear = function(){
	localStorage.clear();
}
var baseInfo = function(){
	var info = JSON.parse(localStorage.getItem(`${unique}user_info`))
	return {
		token: info.token,
		sign: info.secretKey
	}
}

export default{
	set,
	get,
	remove,
	baseInfo,
	clear
}