import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		platform: 'pc',
		isLogin: false,
		areaList: [],
		cateList:[],
		cateArr:[],
		cartTemp: [],
		language: 'es-ES', // es-ES/zh-CN/en-GB
	},
	mutations: {
		setCartTemp(state, value) {
			state.cartTemp = value;
		},
	},
	actions: {},
	modules: {}
})
