import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'swiper/swiper.min.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import Storage from './assets/js/storage.js'
import Dialog from './assets/js/dialog.js'
import Util from './assets/js/util.js'
import i18n from './i18n';

Vue.config.productionTip = false
// 点击预览
let options = {
	fullscreenEl: false,
	closeEl: false, 
	tapToClose: true,
	zoomEl: false,
	tapToToggleControls: false,
	counterEl: false,
	clickToCloseNonZoomable: true
}
Vue.use(preview, options)
// elementUI
Vue.use(ElementUI);
// 全局方法挂载到原型上
//Vue.prototype.$loading = NProgress
Vue.prototype.$storage = Storage
Vue.prototype.$dialog = Dialog
Vue.prototype.$util = Util
Vue.prototype.$bus = new Vue()
// 路由相关操作
window.addEventListener('popstate', function (e) {
    router.isBack = true
},false)
router.beforeEach((to, from, next) => {
	// 跳转到页面回到顶部
	window.scrollTo({
		top: 0, 
		behavior: "smooth" 
	});
	// 设置页面标题
	// if (to.meta.title) {
	// 	document.title = to.meta.title
	// }
	next()
})


new Vue({
	router,
	store,
	i18n,
	render: function(h) {
		return h(App)
	}
}).$mount('#app')
