function isPhone(poneInput) { //手机号正则
	var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
	if (!myreg.test(poneInput)) {
		return false
	} else {
		return true
	};
}

function isEmail(emailStr) { //邮箱正则
	var reg = /^[a-zA-Z0-9]+([._-]*[a-zA-Z0-9]*)*@[a-zA-Z0-9]+.[a-zA-Z0-9{2,5}$]/;
	var result = reg.test(emailStr);
	if (result) {
		return true;
	} else {
		return false;
	}
}

function rand(x, y) { //生成x-y之间的随机数
	var choices = y - x + 1;
	return Math.floor(Math.random() * choices + x);
}

function whatDay() { //今天是周几
	return "周" + "日一二三四五六".charAt(new Date().getDay());
}

//获取时间格式 第二个参数不填则为获取当前时间   yy-MM-dd hh:mm:ss
function getNowFormatDate(str, stamp) {
	Date.prototype.Format = function(fmt) { //author: meizz 
		var o = {
			"M+": this.getMonth() + 1, //月份 
			"d+": this.getDate(), //日 
			"h+": this.getHours(), //小时 
			"m+": this.getMinutes(), //分 
			"s+": this.getSeconds(), //秒 
			"S": this.getMilliseconds() //毫秒 
		};
		if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
		for (var k in o)
			if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" +
				o[k]).substr(("" + o[k]).length)));
		return fmt;
	}
	return stamp == undefined ? (new Date()).Format(str) : (new Date(stamp)).Format(str);
}
// 日期转时间戳  单位:毫秒
function strToTime(date) {
	date = date.replace(/-/g, '/');
	var timestamp = new Date(date).getTime();
	return timestamp;
}

function remove(arr, index) {
	var ARR = arr;
	ARR = arr;
	Array.prototype.remove = function(obj) {
		for (var i = 0; i < this.length; i++) {
			var temp = this[i];
			if (!isNaN(obj)) {
				temp = i;
			}
			if (temp == obj) {
				for (var j = i; j < this.length; j++) {
					this[j] = this[j + 1];
				}
				this.length = this.length - 1;
			}
		}
	}
	return ARR.remove(index);
}

function getTimeArr(start, end) { //传入起始时间生成时间段
	var arr = [];
	for (var i = start; i <= end;) {
		var head = intToTime(i);
		i += 1; //每组时间段的跨度 （单位:小时）
		var ass = intToTime(i);
		arr.push(head + '-' + ass);
	}
	return arr;
}

function intToTime(int) {
	var str = '' + int;
	var ass = str.split('.')[1] == '5' ? '30' : '00';
	var head = int < 10 ? '0' + str.split('.')[0] : str.split('.')[0];
	return head + ':' + ass;
}

function GetDateStr(AddDayCount) { //获取某天的日期
	var dd = new Date();
	dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
	var y = dd.getFullYear();
	var m = dd.getMonth() + 1; //获取当前月份的日期
	var d = dd.getDate();
	m = m < 10 ? '0' + m : m;
	d = d < 10 ? '0' + d : d;
	return y + "-" + m + "-" + d;
}

function checkData(arr){  //检查物资项数据填写是否正确
	var check = arr.findIndex(res=>{
		return (!res.quantity||res.quantity == '')||
		// (!res.total_weight||res.total_weight == '')||
		(!res.unit_price||res.unit_price == '')||
		(!res.suggest_price||res.suggest_price == '')||
		(!res.total_price||res.total_price == '')
	})
	if(arr.length == 0){
		check = 0;
	}
	return check == -1?true:false
}

function checkRecept(arr){
	var check = arr.findIndex(res=>{
		return (!res.invoice_amount||res.invoice_amount == '')||
		(!res.invoice_attachment||res.invoice_attachment == '')||
		(!res.invoice_code||res.invoice_code == '')
	})
	if(arr.length == 0){
		check = 0;
	}
	return check == -1?true:false
}

function isTrue(val){
	if(val == null||val == ''||val == 'null'||val == undefined){
		return false
	}else{
		return true
	}
}
function toTree(data, id, pid){
	data.forEach(function(item) {
		delete item.children;
	});
	var map = {};
	data.forEach(function(item) {
		map[item[id]] = item;
	});
	var val = [];
	data.forEach(function(item) {
		var key = item[pid]||'000000';
		var parent = map[key];
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			val.push(item);
		}
	});
	return val;
}

export default {
	isPhone,
	isEmail,
	rand,
	whatDay,
	getNowFormatDate,
	remove,
	strToTime,
	getTimeArr,
	GetDateStr,
	getNowFormatDate,
	checkData,
	isTrue,
	checkRecept,
	toTree
}
