import Cookies from 'js-cookie'

const UserKey = 'user_coupon'

export function getCoupon() {
  return Cookies.get(UserKey)
}

export function setCoupon(token) {
  return Cookies.set(UserKey, token)
}

export function removeCoupon() {
  return Cookies.remove(UserKey)
}