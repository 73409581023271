import axios from 'axios';
import qs from 'qs';
import instance from '../main.js'
import Vue from 'vue'
var vm = new Vue

const appBaseURL = 'https://api.giamia.es/';
//const appBaseURL = 'http://www.mygiamia.com/';
// const appBaseURL = '';

class Base {
	constructor(baseURL = appBaseURL) {
		// 创建实例
		this.$http = axios.create({
			 baseURL: baseURL,
			 timeout: 30000,
			 // withCredentials: true
		});
		// 配置
		this.$http.interceptors.request.use(
			config => {
				// config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
				return config;
			},
			error => Promise.reject(error)
		);
		// 拦截器
		this.$http.interceptors.response.use(
			response => {
				if(response.data.status == 'SUCCESSS'){
					return response;
				}else if(response.data.code == 2003){
					vm.$bus.$emit('login');
				}else{
					if(response.data.code != 2003){
						vm.$message.warning(response.data.msg);
					}
					return response;
				}
			},
			error => {
				switch (error.response.status) {
					case 401:
					case 403:
						instance.$router.push({
							name: 'login'
						});
						return error.response;
					default:
						return Promise.reject(error);
				}
			}
		);
	}

	get(url, config = {}) {
		return this.$http.get(url, {
			...config,
			params: {
				...config.params,
			},
		});
	}
	post(url, data = undefined, config = {}) {
		return this.$http.post(url, qs.stringify(data), config);
	}
	put(url, data = undefined, config = {}) {
		return this.$http.put(url, data, config);
	}
	delete(url, config = {}) {
		return this.$http.delete(url, config);
	}
}

export default Base;
